import React, { useState, useEffect } from "react";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";

import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { withStyles } from "@mui/styles";

export const ButtonShadowed = withStyles((theme) => ({
  contained: {
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);",
  },
}))(Button);

let paymentInProcess = false;
const StripePaymentButton = (props) => {
  const [supportedPaymentButton, setSupportedPaymentButton] = useState({});
  const [message, setMessages] = useState([]);

  const createOperationLog = (b) => {
    console.log({ operationalLog: b });
    setMessages((a) => [...a, b]);
  };
  const createCriticalLog = (b) => {
    console.log({ criticalMessage: b });
    setMessages((a) => [...a, b]);
  };
  const {
    setIsLoading,
    notifyError: setMessage,
    handleIntent,
    handlePayment,
  } = props;
  const { getConfirmation } = props;
  const { markPaidOverSocket, children } = props;
  const { kycVerified } = props;

  const stripe = useStripe();

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentIsAllowed, setPaymentIsAllowed] = useState(true);
  const [loading, setLoading] = useState();
  const history = useHistory();
  const { tableId } = useParams();

  const netTotal = props.totalPayment;

  const updatePaymentStage = (state) => {};

  useEffect(() => {
    if (stripe) {
      const payload = {
        country: props?.outlet?.countryCode,
        currency: String(props?.outlet?.currencyCode).toLowerCase(),
        total: {
          label: "Restaurant bill",
          amount: netTotal,
        },
        requestPayerName: true,
        requestPayerEmail: false,
      };
      const pr = stripe.paymentRequest(payload);
      setLoading(true);
      createOperationLog("Calling stripe payment request api", {
        requestPayload: payload,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment()
        .then((result) => {
          console.log({ result });
          createOperationLog("Stripe payment request api call succeeded", {
            responsePayload: result,
          });
          if (result) {
            setSupportedPaymentButton(result);
            setPaymentRequest(pr);
          }
        })
        .catch((e) => {
          createCriticalLog("Stripe payment request api call failed", {
            responsePayload: e,
          });
        })
        .finally(() => setLoading(false));
    }
  }, [stripe, netTotal]);

  React.useEffect(() => {
    createOperationLog(
      `Payment button => checking payment is valid because  ${netTotal} has changed`
    );
  }, [netTotal]);

  const redirectForFeedback = (paymentId) => {
    handlePayment.onPaymentComplete();
    setIsLoading(false);
    // markPaidOverSocket(totalAmount);
  };

  const initiatePaymentConfirmation = () => {
    props.handelPaymentConfirmation();
  };

  const onPaymentMethod = async (ev) => {
    console.log({ paymentInProcess }, "Into on Paymentmethod");
    if (paymentInProcess) {
      console.log("Avoiding multiple confirm");
      return null;
    }
    paymentInProcess = true;
    // Confirm the PaymentIntent without handling potential next actions (yet).
    createOperationLog("Creating payment intent");
    const { clientSecret } = await handleIntent();
    stripe
      .confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      )
      .then((confirmResult) => {
        createOperationLog("Payment button confirm call succeeded", {
          responsePayload: confirmResult,
        });
        if (confirmResult.error) {
          setMessage(confirmResult.message);
          handlePayment
            .onPaymentFail({ message: confirmResult.message })
            .then((res) => props.goToPreviousPage());

          createCriticalLog("Payment button confirm call returned error", {
            responsePayload: confirmResult.error,
          });
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete("fail");
        } else {
          createOperationLog("Payment button confirm call returned success", {
            responsePayload: confirmResult,
          });
          if (confirmResult.paymentIntent.status === "requires_capture") {
            initiatePaymentConfirmation();
            ev.complete("success");
          }

          if (confirmResult.paymentIntent.status === "succeeded") {
            initiatePaymentConfirmation();
            ev.complete("success");
          }
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success");
          //redirectForFeedback();
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (confirmResult.paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            stripe.confirmCardPayment(clientSecret).then((result) => {
              if (result.error) {
                setMessage(result.message);
                // The payment failed -- ask your customer for a new payment method.
              } else {
                console.log("Redirected from line 140");
                redirectForFeedback();
                // The payment has succeeded.
              }
            });
          } else {
            console.log("Redirected from line 146");
            initiatePaymentConfirmation();
            // The payment has succeeded.
          }
        }
      })
      .finally(() => {
        paymentInProcess = false;
      });
  };

  const openPaymentMethod = (paymentIsAllowed) => {
    createOperationLog("Payment button => openPaymentMethod stared ");

    if (paymentRequest) {
      setIsLoading(true);
    }
    createOperationLog("Payment button => Verified payment request object");

    if (!paymentIsAllowed) {
      createOperationLog(
        `Payment button => paymentIsAllowed failed with response =  ${paymentIsAllowed}`
      );
      setIsLoading(false);
      return;
    }
    createOperationLog(
      `Payment button => paymentIsAllowed passed with response =  ${paymentIsAllowed}`
    );
    return !paymentRequest ? false : setTimeout(() => paymentRequest.show(), 1);
  };

  const notAllowed = async () => {
    setIsLoading(false);
    console.log("Button not found");
    setMessage(
      "Currently this payment method is not supported by your browser"
    );
  };
  if (loading) {
    return (
      <ButtonShadowed
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        style={{
          fontSize: 20,
          fontWeight: "bold",
          borderRadius: 10,
        }}
        elevation={5}
      >
        <CircularProgress color="secondary" />
      </ButtonShadowed>
    );
  }

  if (paymentRequest) {
    paymentRequest.on("paymentmethod", onPaymentMethod);
    paymentRequest.on("cancel", () => setIsLoading(false));
    return (
      <span onClick={(e) => openPaymentMethod(paymentIsAllowed)}>
        {/* </span>{JSON.stringify(message)} */}
        {React.cloneElement(props.children, {
          supportedPaymentButton,
        })}
      </span>
    );
  }

  return <span onClick={notAllowed}>{children}</span>;
};

export default StripePaymentButton;
